import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as LogoIcon } from "../assets/logo.svg";
import { ReactComponent as CheckIcon } from "../assets/check.svg";
import { ReactComponent as AddIcon } from "../assets/add.svg";
import useStyles from "../style";

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <header className={classes.header}>
          <div className={classes.logo}>
            <a rel="noopener noreferrer" href="https://thegamers.com.br/">
              <LogoIcon />
            </a>
          </div>

          <div className={classes.headerButtons}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://facebook.com/thegamers.com.br/"
            >
              <img
                width={120}
                src={require("../assets/facebook.png")}
                alt="facebook"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/thegamers.com.br/"
            >
              <img
                width={120}
                src={require("../assets/instagram.png")}
                alt="instagram"
              />
            </a> 
            <Button
              size="small"
              variant="outlined"
              component="a"
              href="https://thegamers.com.br/login"
              className={classes.loginButton}
            >
              Login
            </Button>
          </div>
        </header>

        <div className={classes.mediasContent}>
          <div className={classes.medias}></div>
        </div>

        <div>
          <Grid container spacing={2}>
            <Grid item sm={7}>
              <div className={classes.titleContent}>
                <Typography component="h1" className={classes.title}>
                  <span>Compartilhe Momentos</span> dos Seus Jogos e Ganhe
                  Seguidores!
                </Typography>
              </div>
              <Typography className={classes.subtitle}>
                Estamos <span>no ar</span>! Finalmente a <span>The Gamers</span>{" "}
                chegou, agora você pode começar a usar a rede para compartilhar
                suas gameplays, memes de jogos, ganhar níveis e experiência,
                além de poder completar missões e conhecer pessoas para jogar.
              </Typography>
              <div className={classes.form}>
                <Button
                  size="large"
                  variant="contained"
                  className={classes.button}
                  component="a"
                  href="https://thegamers.com.br/register"
                  disableElevation
                >
                  Criar Cadastro
                </Button>
                <section className={classes.storeSection}>
                  <a
                    className={classes.btGoogle}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=br.com.thegamers.production"
                  >
                    <img
                      height={46}
                      src={require("../assets/google_play.png")}
                      alt="google play"
                    />
                  </a>

                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://apps.apple.com/br/app/the-gamers/id1659935711"
                  >
                    <img
                      height={46}
                      src={require("../assets/app_store.png")}
                      alt="apple store"
                    />
                  </a>
                </section>
              </div>
            </Grid>

            <Grid item sm={5}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box width="100%" maxWidth={350}>
                  <Typography className={classes.text} component="div">
                    <Box display="flex" alignItems="center">
                      <CheckIcon className={classes.icon} />
                      Torne-se um influenciador digital de games
                    </Box>
                  </Typography>
                  <Typography className={classes.text} component="div">
                    <Box display="flex" alignItems="center">
                      <CheckIcon className={classes.icon} />
                      Personalize seu próprio Avatar na rede
                    </Box>
                  </Typography>
                  <Typography className={classes.text} component="div">
                    <Box display="flex" alignItems="center">
                      <CheckIcon className={classes.icon} />
                      Compartilhe prints, vídeos e momentos
                    </Box>
                  </Typography>
                  <Typography className={classes.text} component="div">
                    <Box display="flex" alignItems="center">
                      <CheckIcon className={classes.icon} />
                      Crie conteúdo original e ganhe assinantes
                    </Box>
                  </Typography>
                  <Typography className={classes.text} component="div">
                    <Box display="flex" alignItems="center">
                      <AddIcon className={classes.icon} />
                      Bem, beeeem mais coisas em breve!
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Home;